import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/main.css';
import * as animationData from './data.json';
import history from '../../../history';
import { beatEstimateKnowMore } from '../../../gtm/gtmServices';
import autoUtmHistory from '../../../_services/autoUtmHistory';

const BeatThePrice = props => {
  useStyles(s);
  const [showknowMorePop, setShowKnowMorePop] = useState(false);
  const [loader, setLoader] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const knowMore = (e) =>{
    e.preventDefault();
    setLoader(true);
    let url = ' '
    try{
      if(window){
        url = window.location.href;
      }else{
        url = `https://gomechanic.in/${history.location.path}`;
      }
    }catch(e){
      url = `https://gomechanic.in/${history.location.path}`;
    }
    beatEstimateKnowMore(url,props.city?.name)
    autoUtmHistory(history).push('/beat-car-repair-price');
  }

  return (
    <>
      <div className={s.beatThePrice}>
        <div
          className={s.beatThePriceBox}
          onClick={knowMore}
        >
          <div className={s.textControl}>
            <div className={s.headingBeatThePrice}>
              {props.home?.beatEstimate?.title}
            </div>
            <p className={s.text}>{props.home?.beatEstimate?.subTitle}</p>

            <div className={s.knowMore}>
              {loader ? <div className={s.loader}></div> : 'KNOW MORE'}
            </div>
          </div>
          <div className={s.ImgDiv}>
            <img
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/NearMe/coin%204.gif"
              className={s.coinsImg}
            />
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: action => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeatThePrice);
