import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import s from './main.css';
import InusuranceProgramPop from './InusuranceProgramPop';
import { tapGomAssurance } from '../../../gtm/gtmServices';

const InusuranceProgram = props => {
  const [showInsurancePop, setShowInsurancePop] = useState(false);

  useStyles(s);
  return (
    <>
      {showInsurancePop ? (
        <InusuranceProgramPop
          hide={() => {
            setShowInsurancePop(false);
            const z = document.getElementsByTagName('body');
            z[0].style.overflow = 'scroll';
          }}
        />
      ) : null}
      <div>
        <div className={s.insuranceBox}>
          <div className={s.ImgDiv}>
            <img src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/assurance-icon.png" className={s.ClaimImg} />
          </div>

          <div
            className={s.textControl}
            onClick={e => {
              e.preventDefault();
              setShowInsurancePop(true);
              tapGomAssurance()
            }}
          >
            <div className={s.headingInsurance}>
              GoMechanic Assurance Program
            </div>
            <ul>
                <li className={classNames(s.list, s.listAlign1)}>
                  {props.isMalaysiaRequest ?  'Upto RM 1500 insurance protection with every service request.' : 'Upto Rs. 25000 insurance protection with every service request.'}
                </li>
                <li className={classNames(s.list, s.listAlign1)}>
                  Program protects users against damage caused during free
                  pickup and delivery of car.
                </li>
            </ul>
            <div className={s.knowMore}>KNOW MORE</div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: action => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InusuranceProgram);
