import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getServiceBuddyApi = (citySlug) => {
    const Headers = new HttpHeader();
    const HttpHandler = new Http();

    const headers = Headers.get_token_headers();
    let url;

    url = `/api/v1/get-service-buddy?city=${citySlug}`;

    return HttpHandler.get(url, headers);
};
