// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3dGeF{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;vertical-align:middle;-ms-flex-align:center;align-items:center;width:100%;height:235px;margin-top:8px;-o-object-fit:contain;object-fit:contain;background-color:#282828;border-radius:8px}._3qi7Q{position:absolute}.BrE2-,._3bp1_{z-index:2;margin-left:auto;margin-right:auto;width:60px}._3lcsD,._1hCaO{position:relative;z-index:2;top:-61%;left:45%;width:60px}.BrE2- img,._3lcsD img,._3bp1_,._1hCaO,._1hCaO img{width:60px}._2qlIt{width:100%;height:100%;border-radius:8px}._1ptFD{display:grid;grid-template-columns:4fr 1fr;margin-bottom:-32px}._19bgg{width:90%;height:42vh;background-color:#f6f6f6;margin-left:0;margin-top:1.5714rem;margin-bottom:2.1429rem}.Pcdzv{grid-row-gap:35px;row-gap:35px;background-color:#fff;padding-top:30px;padding-bottom:10px;margin-left:60px;display:grid;-ms-flex-pack:justify;justify-content:space-between}._2FGsx{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:0;font-size:25.6px;font-size:1.6rem;letter-spacing:.16px;letter-spacing:.01rem;color:#071c2c;justify-items:center;background-color:#fff}._2FGsx,._1XA1N{font-weight:600;font-stretch:normal;font-style:normal;text-align:center}._1XA1N{font-size:11.4px;font-size:.7125rem;letter-spacing:.12px;letter-spacing:.0075rem;color:grey}", ""]);
// Exports
exports.locals = {
	"videoCard": "_3dGeF",
	"playIc": "_3qi7Q",
	"hide": "BrE2-",
	"visible": "_3bp1_",
	"hides": "_3lcsD",
	"visibles": "_1hCaO",
	"bgImg": "_2qlIt",
	"flexBox": "_1ptFD",
	"videoBox": "_19bgg",
	"container": "Pcdzv",
	"ratingItemVideo": "_2FGsx",
	"subHeading": "_1XA1N"
};
module.exports = exports;
