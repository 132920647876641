/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import ManufacturerSelector from './CarBrandSelector';
import CarModelSelector from './CarModelSelector';
import FuelTypeSelector from './FuelTypeSelector';
import styles from '../Modal/Modal.css';
import setUserCar from '../../actions/cardetails';
import { isPhonePeBrowser } from '../../utilities';
import history from '../../history';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { setCart } from '../../actions/setCart';
import { setCarForCart } from '../../actions/setCarForCart';
import {
  selectCarBrandGtmEvent, selectCarModelGtmEvent, accessoriesCarSelectionEvent, accessoriesCarBrandSelectionEvent, accessoriesCarModelSelectionEvent, accessoriesCarFuelSelectionEvent, accessoriesCarYearSelectionEvent, selectCarFuelGtmEvent,
} from '../../gtm/gtmServices';
import setAccessoriesCar from '../../actions/accessoriesCarDetail';
import CarYearSelector from './CarYearSelector';
import autoUtmHistory from '../../_services/autoUtmHistory';
import {fetchAllBrands} from '../../_services/api/fetchAllBrands';
import {setAllBrands} from '../../actions/setAllBrands';

const getCurrentStep = (props) => {
  if (props.carSelectionStep === 'brand') return 0;
  if (props.carSelectionStep === 'model') return 1;
  if (props.carSelectionStep === 'fuel') return 2;
  if (props.accessoriesCar && props.carSelectionStep === 'year') return 3;
};

const SelectorMain = (props) => {
  useStyles(styles);


  let carSelectionStep = 0;
  const [initialCarDetails, setInitialCarDetails] = props.accessoriesCar ? useState({
    brand: props.accessoriesCarDetail.brand,
    model: props.accessoriesCarDetail.model,
    fuel: props.accessoriesCarDetail.fuel,
    year: props.accessoriesCarDetail.year,
  }) : useState({
    brand: props.cardetails?.brand,
    model: props.cardetails?.model,
    fuel: props.cardetails?.fuel,
  });

  useEffect(() => {
    setInitialCarDetails({
      brand: props.cardetails?.brand,
      model: props.cardetails?.model,
      fuel: props.cardetails?.fuel,})
    
    if(props.cardetails?.brand && !props.cardetails?.model && !props.cardetails?.fuel)
      SetStep(1);
  },[props.cardetails?.brand, props.cardetails?.model, props.cardetails?.fuel])

  const hide = () => {
    if (props.accessoriesCar) props.action(setAccessoriesCar(initialCarDetails));
    else props.action(setUserCar(initialCarDetails));

    props.hide();

    if (!props.accessoriesCar && !props.hide) {
      autoUtmHistory(history).replace(generate({
        city: props.city,
        subCity: props.selectedRegion || null,
        serviceType: props.serviceType,
        brand: initialCarDetails.brand,
        model: initialCarDetails.model,
        fuel: initialCarDetails.fuel,
      }));
    }
  };

  if (props.carSelectionStep) carSelectionStep = getCurrentStep(props);

  const [step, SetStep] = useState(carSelectionStep);

  const goToNext = () => {
    if (!props.accessoriesCar) {
      if (step === 2) props.hide();
      SetStep(step !== 2 ? step + 1 : 2);
    } else {
      if (step === 3) props.hide();
      SetStep(step !== 3 ? step + 1 : 3);
    }
  };

  const goToPrev = () => {
    if (step === 0) props.hide();
    SetStep(step !== 0 ? step - 1 : 0);
  };

  // const [carDetails, setCarDetailsState] = useState({
  //   // expecting object here, not the text
  //   brand: null,
  //   model: null,
  //   fuel: null,
  // });
  //
  //

  const carDetails = props.accessoriesCar ? props.accessoriesCarDetail : props.cardetails;
  // props.action(setUserBike(carDetails))


  const setCarDetails = (carDetails) => {
    // if (carDetails.brand && carDetails.model && carDetails.fuel) props.action(setUserCar(carDetails));

    if (props.accessoriesCar) {
      props.action(setAccessoriesCar(carDetails));
    } else props.action(setUserCar(carDetails));

    if (props.redirectOnlyOnFinal && carDetails.fuel && carDetails.fuel.car_type_id) {
      // window.location.href = generate({
      //   city: props.city,
      //   subCity: props.selectedRegion || null,
      //   service: props.serviceDetailsData ? props.serviceDetailsData : props.serviceType,
      //   brand: carDetails.brand,
      //   model: carDetails.model,
      //   fuel: carDetails.fuel,

      // })
      autoUtmHistory(history).replace(generate({
        city: props.city,
        subCity: props.selectedRegion || null,
        service: props.serviceDetailsData ? props.serviceDetailsData : props.serviceType,
        brand: carDetails.brand,
        model: carDetails.model,
        fuel: carDetails.fuel,

      }));
    }
    if (isPhonePeBrowser()) return;
    if (props.redirect) {
      autoUtmHistory(history).replace(generate({
        city: props.city,
        subCity: props.selectedRegion || null,
        serviceType: props.serviceType,
        brand: carDetails.brand,
        model: carDetails.model,
        fuel: carDetails.fuel,

      }));
    }
    // }
  };

  useEffect(() => {
    // accessoriesCarSelectionEvent({
    //   user_id: props.user ? props.user.id : null,
    // });
    !props.allBrands?.length && fetchAllBrands().subscribe(res => {
      props.action(setAllBrands(res?.data))
    }, (error) => {
      console.log(error);
    })
  }, []);

  return (
    <>
      {!props.noOverlay && (
        <div className={classNames(styles.overlay, styles.dBlock)} />
      )}
      {step === 0 ? (
        <ManufacturerSelector
          accessoriesCar={props.accessoriesCar}
          onBrandSelect={(brand) => {
            if(props.isFlipkartScreen) {
              props.setAvailableFlipkartBrandModelFuel({models: 1}, props.carBrandModelFuelDataFlipkart.brands, brand)
            }
            setCarDetails({
              ...carDetails, brand, model: null, fuel: null, year: null,
            });
            if (!props.accessoriesCar) selectCarBrandGtmEvent(
              props.user ? props.user.id : null, null, brand.name, brand.id
            );
            else {
              accessoriesCarBrandSelectionEvent({
                user_id: props.user ? props.user.id : null,
                car_brand_name: brand.name,
              });
            }
          }}
          noOverlay={props.noOverlay}
          fixedHeight={props.fixedHeight}
          desktopCarSelection={props.desktopCarSelection}
          hide={hide}
          goToNext={goToNext}
          goToPrev={goToPrev}
          noBackButton
          initialCarDetails={initialCarDetails}
          availableFlipkartCouponBrands={props.carBrandModelFuelDataFlipkart && props.carBrandModelFuelDataFlipkart.brands}
          isFlipkartScreen={props.isFlipkartScreen} 
        />
      ) : null}
      {step === 1 ? (
        <CarModelSelector
          accessories={props.accessoriesCar}
          onModelSelect={(model) => {
            if(props.isFlipkartScreen) {
              props.setAvailableFlipkartBrandModelFuel({fuels: 1}, props.carBrandModelFuelDataFlipkart.models, model)
            }
            setCarDetails({
              ...carDetails, model, fuel: null, year: null,
            });
            if (!props.accessoriesCar) selectCarModelGtmEvent(
              props.user ? props.user.id : null, null, model.name, model.id, carDetails.brand.name, carDetails.brand.id,
            );
            else {
              accessoriesCarModelSelectionEvent({
                user_id: props.user ? props.user.id : null,
                car_brand_name: carDetails.brand ? carDetails.brand.name : null,
                car_model_name: model.name,
              });
            }
          }}
          noOverlay={props.noOverlay}
          fixedHeight={props.fixedHeight}
          desktopCarSelection={props.desktopCarSelection}
          hide={hide}
          goToNext={goToNext}
          goToPrev={goToPrev}
          initialCarDetails={initialCarDetails}
          availableFlipkartCouponModels={props.carBrandModelFuelDataFlipkart && props.carBrandModelFuelDataFlipkart.models}
          isFlipkartScreen={props.isFlipkartScreen} 
        />
      ) : null}
      {step === 2 ? (
        <FuelTypeSelector
          accessories={props.accessoriesCar}
          onFuelSelect={(fuel) => {
            setCarDetails({ ...carDetails, fuel, year: null });
            if (!props.accessoriesCar) {
              selectCarFuelGtmEvent(
                props.user ? props.user.id : null, null, fuel.name, carDetails.model.name, carDetails.model.id, carDetails.brand.name, carDetails.brand.id,
              );
              if (initialCarDetails.fuel
                && fuel && fuel.car_type_id !== initialCarDetails.fuel.car_type_id) {
                // props.action(setCart([]));
              }
              props.action(setCarForCart({ ...carDetails, fuel }));
            } else {
              accessoriesCarFuelSelectionEvent({
                user_id: props.user ? props.user.id : null,
                car_brand_name: carDetails.brand ? carDetails.brand.name : null,
                car_model_name: carDetails.model ? carDetails.model.name : null,
                fuel_type: fuel.name,
              });
            }
          }}
          noOverlay={props.noOverlay}
          fixedHeight={props.fixedHeight}
          desktopCarSelection={props.desktopCarSelection}
          hide={hide}
          goToNext={goToNext}
          goToPrev={goToPrev}
          initialCarDetails={initialCarDetails}
          availableFlipkartCouponFuel={props.carBrandModelFuelDataFlipkart && props.carBrandModelFuelDataFlipkart.fuels}
          isFlipkartScreen={props.isFlipkartScreen}
        />
      ) : null}
      {step === 3 ? (
        <CarYearSelector
          onYearSelect={(year) => {
            setCarDetails({ ...carDetails, year });
            if (props.carChangeCallback) props.carChangeCallback({ ...carDetails, year });
            accessoriesCarYearSelectionEvent({
              user_id: props.user ? props.user.id : null,
              car_brand_name: carDetails.brand ? carDetails.brand.name : null,
              car_model_name: carDetails.model ? carDetails.model.name : null,
              fuel_type: carDetails.fuel ? carDetails.fuel.name : null,
              manufacture_year: year,
            });
          }}
          noOverlay={props.noOverlay}
          desktopCarSelection={props.desktopCarSelection}
          hide={hide}
          goToNext={goToNext}
          goToPrev={goToPrev}
          initialCarDetails={initialCarDetails}
        />
      ) : null }
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorMain);