// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3hyhS{display:grid;grid-template-columns:1fr 1fr;grid-row-gap:4.6875rem}._3CiTC{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;margin-right:1.5625rem}._3kCpE{width:130px;width:8.125rem;height:100px;height:6.25rem;-o-object-fit:cover;object-fit:cover}._1tNd9{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-left:1.25rem}._3dawp{font-size:22px;font-size:1.375rem;font-weight:600;line-height:1.05;letter-spacing:.2px;letter-spacing:.0125rem;color:#282828;margin-top:.625rem;margin-bottom:.9375rem}._2nc9q,._3dawp{font-stretch:normal;font-style:normal;text-align:left}._2nc9q{font-size:15px;font-size:.9375rem;font-weight:400;line-height:1.27;letter-spacing:.14px;letter-spacing:.00875rem;color:#4a4a4a}", ""]);
// Exports
exports.locals = {
	"container": "_3hyhS",
	"benefitItem": "_3CiTC",
	"benefitImage": "_3kCpE",
	"benefitTitleSubtitle": "_1tNd9",
	"title": "_3dawp",
	"subtitle": "_2nc9q"
};
module.exports = exports;
