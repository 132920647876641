import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './main.css';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import history from '../../../history';
import autoUtmHistory from '../../../_services/autoUtmHistory';
import { popularServiceGtmEvent } from '../../../gtm/gtmServices';
import { getServiceById } from '../../../_services/api/getServiceById';

const NewBlogSection = (props) => {
  useStyles(s);

  const [ data ,setData ] = useState({});
  const carExists = props.cardetails?.brand && props.cardetails?.model && props.cardetails?.fuel;

  const quryStr = (key) => {
    let anch = props.deeplink;
    return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
  }

  const pageName = String(quryStr('pagename'));
  const catId = Number(quryStr('category_id'));

  useEffect(() => {
    if(pageName === "service_detail_page") {
    if(props.carId && carExists) {
      getServiceById(
        props.serviceData.retail_service_type_id,
        props.serviceData.id,
        props.carId,
        props.city?.id,
      ).subscribe((res) => {
        // console.log("re cfd dfdvv df f", res.data);
        setData(res.data);
      })
    }
  }
  }, [props.cardetails, props.city]);


  return (
    <React.Fragment key={props.keyId}>
      <div className={s.blogSectionMain}>
        <div 
          className={s.innerSection}
          onClick={(e) => {
            e.preventDefault();
            if(pageName === "service_detail_page" && carExists) {
              autoUtmHistory(history).push(`${generate({
                city: props.city,
                service: data,
                brand: props.cardetails?.brand,
                model: props.cardetails?.model,
                fuel: props.cardetails?.fuel,
              })}`,data);
              popularServiceGtmEvent({
                category_id: catId,
                item_id: props.serviceData.id,
                item: props.serviceData.trending_name,
              });
            }
            else {
              const serviceType = props.servicesTypesList.find(
                (item) => { 
                  if(item.id === catId)  {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              ) 
              props.action(setActiveServiceType(serviceType || null));
              autoUtmHistory(history).push(`${generate({
                city: props.city,
                serviceType: serviceType || null,
                brand: props.cardetails?.brand,
                model: props.cardetails?.model,
                fuel: props.cardetails?.fuel,
              })}`);
              popularServiceGtmEvent({
                category_id: catId,
                item_id: props.serviceData.id,
                category: props.serviceData.trending_name,
              });
            }
          }}
        >
          {/* <div className={s.backgroundImage}>
            <img
              className={s.topImage}
              src={props.articleImage}
              alt="Popular service"
            />
          </div>
            <img className={s.trendingImg} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/InsuranceServicesHelpers/blogSection/group_3.svg" alt="img" />
            <div className={s.trendingText}>Trending</div>
            <div className={s.innerTextSection}>
            <div className={s.servicesName}>{props.seviceText}</div>
            <div className={s.subName}>
              {props.description}
            </div>
            <div className={s.line} />
            <div className={s.descrption}><span className={s.pastBooking}>{props.pastBooking}</span> {props.trendingText}</div>
          </div> */}

          <div>
            <img 
              className={s.topImage}
              src={props.articleImage}
              alt="Popular service"
            />
            <div className={s.trendingTxtContainer}>
              <img 
                src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Trending%20Icon.png"
                className={s.trendingImg}
                alt="img"
              />
              <div className={s.descrption}><span className={s.pastBooking}>{props.pastBooking}</span> {props.trendingText}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBlogSection);
