/**
 * Author: Rishabh Tyagi <rishabhtyagi@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a54d2d3af23439013ad
 *
 * This file contains code for Insurance Assurance Section for PC
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import s from './css/main.css';
import noScrollBody from '../../../_services/bodyNoScrollForModals';
import chatBotDisplay from '../../../_services/hideChatBot';

const InusuranceProgramPop = props => {
  // console.log('props for insurance ', props);
  function convertToBold(list) {
    const fields = list.split('~');
    return (
      <>
        <span>{fields[0]}</span>
        <span className={s.bold}>{fields[1]}</span>
        <span>{fields[2]}</span>
      </>
    );
  }

  const setinusuranceList = props.home?.InsuranceProgram[0].list;
  useStyles(s);
  useEffect(() => {
    noScrollBody(false);
    chatBotDisplay(false);
    return () => {
      noScrollBody(true);
      chatBotDisplay(true);
    };
  }, []);

  return (
    <>
      <div onClick={props.hide} className={s.overlay} />
      <div className={classNames(s.modaldialog, s.modalheight)}>
        <div className={s.popBox}>
          <div className={s.heading}>
            {props.home?.InsuranceProgram[0].heading}
          </div>
          <div className={s.closeIns}>
            <img src="/icons/ins-close.png" onClick={props.hide} />
          </div>
          <div className={s.popupLine} />
          <div className={s.scroll}>
            <ul>
              {setinusuranceList
                .slice(0, Math.ceil(setinusuranceList.length))
                .map(list => (
                  <li className={s.insuranceList}>{convertToBold(list)}</li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: action => dispatch(action),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InusuranceProgramPop);
