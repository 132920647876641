/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from '../Homepage/css/main.css';
import styles from './Page404.css';
import schema from '../Homepage/validationSchema';
import Rating from '../Rating/Rating';
import HomepageSection from '../Homepage/HomepageSection/HomepageSection';
import AppDetails from '../Homepage/AppDetails/AppDetails';
import SelectorMain from '../ModalSelectors/SelectorMain';
import RatingReviews from '../Homepage/RatingReviews/RatingReviews';
import ServicesGuide from '../../routes/servicesGuide/ServicesGuide';
import Partners from '../Homepage/Partners/Partners';
import HideOnTabPC from '../../_wrappers/HideOnTabPC';
import HideOnMobile from '../../_wrappers/HideOnMobile';
import Page404PcHeader from '../Homepage/Page404PcHeader';
import PcHomeForm from '../PcHomeForm/PcHomeForm';
import HowGomechWorks from '../Homepage/HowGomechWorksPC/HowGomechWorks';
import WorksMob from '../Homepage/WorksMob/WorksMob';
import GomechBenefitsPC from '../Homepage/GomechBenefitsPC/GomechBenefitsPC';
import NewFaqAccordion from '../NewFAQ/NewFaqAccordion';
import InusuranceProgram from '../Homepage/InsuranceProgramPop/InsuranceProgram';
import Link from '../Link/Link';
import PcHomepageNavigationBar from '../PcHomepageNavigationBar/PcHomepageNavigationBar';
import { getBrandName, getFuelName, getModelName } from '../../_services/carDetailGetters';
import { HomepageSections } from '../../constants';
import BottomSlider from '../InsuranceServicesHelpers/BottomSlider/BottomSlider';
import FooterPage from '../FooterPage/FooterPage';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { setActiveServiceType } from '../../actions/serviceType';
import history from '../../history';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import GoMechanicBenefits from '../Homepage/GoMechanicBenifits/GoMechanicBenifits';
import Toast from '../Toast/Toast';
import Global from '../../global';
import HideOnMobileTab from '../../_wrappers/HideOnMobileTab';
import Popup from '../EnquiryPopup/Popup';
import CarouselNew2 from '../CarouselNew2/CarouselNew2';
import ReviewsCarousel from '../ReviewsCarousel/ReviewsCarousel';
import PopularService from '../Homepage/PopularService';
import LuxeService from '../Homepage/LuxeService';
import MonsoonService from '../Homepage/MonsoonService/MonsoonService';
import DisplayWrapper from '../../_wrappers/DisplayWrapper/DisplayWrapper';
import BeatThePrice from '../Homepage/BeatThePrice/BeatThePrice';
import { createLead } from '../../_services/api/leads';
import { setLeadAction } from '../../actions/lead';
import autoUtmHistory from '../../_services/autoUtmHistory';
import { clickCategoryBtnEvent, retailSelectCarEvent, tapCheckPriceGtmEvent, viewItemListGtmEvent } from '../../gtm/gtmServices';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from '../Homepage/carouselPlaceholder.png';
import { cityIdsForMandatoryPhoneNum } from '../../utilities';
import setToastConfigAction from '../../actions/setToastConfig';
import HomeVideo from '../Homepage/HomeVideo/HomeVideo';
import TopContent from '../Homepage/TopContent';
import ServiceBuddyCarousel from '../ServiceBuddyCarousel/ServiceBuddyCarousel';
import CarouselSkeleton from '../Skeleton/CarouselSkeleton';
import WorkShopNearYou from '../Homepage/WorkShopNearYou';
import { fetchWorkshopsNearYou } from '../../_services/api/fetchWorkshopsNearYou';
import { getQueryStringValue } from '../../utilities';

const Page404 = (props) => {
  useStyles(s);
  useStyles(styles);

  const [mobile, setMobile] = useState('');
  const [formValid, SetFormValid] = useState(false);
  const [mobileChanged, setMobileChanged] = useState(false);

  const [carSelectorShow, SetCarSelectorShow] = useState(false);

  const ApiManager = new Global();

  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1000,
  });

  const [showPopup, setShowPopup] = useState(false);

  const [showInsurancePop, setShowInsurancePop] = useState(false);

  const { displayPossibleSctions } = props;

  const hideScroll = () => {
    const x = document.getElementsByTagName('body');
    x[0].style.overflow = 'hidden';
  };

  schema
    .isValid({ mobile })
    .then((res) => SetFormValid(res))
    .catch(console.log);

  const handleMobileChange = (event) => {
    if(props.isCountry === 'India') {
      if (isNaN(event.target.value) || event.target.value.length > 10) {
        return false;
      }
    } else if(props.isMalaysiaRequest) {
      if (isNaN(event.target.value) || event.target.value.length > 11) {
        return false;
      }
    }
    setMobileChanged(true);
    setMobile(event.target.value);
  };

  // useEffect(
  //   () => {
  //     if (!mobileChanged && mobile.length === 0 && props.user && props.user.mobile && props.user.mobile.length) setMobile((props.user && props.user.mobile && props.user.mobile.length > 0) ? props.user.mobile : '');
  //   },
  //   schema
  //     .isValid({ mobile })
  //     .then((res) => SetFormValid(res))
  //     .catch(console.log('error')),
  // );

  // useEffect(
  //   () => {
  //     ScrollOut({
  //       onShown(el) {
  //         const floater = document.getElementById('floater');
  //         if (!floater) {
  //           // console.log('not found ', floater);
  //           return;
  //         }
  //         floater.style.display = 'none';
  //         // floater.style.top = '0px';
  //
  //         // `${el.getBoundingClientRect().top}px`;
  //         // console.log('trigger shown ', floater);
  //       },
  //       onHidden(el) {
  //       // hide the element initially
  //         const floater = document.getElementById('floater');
  //
  //         if (!floater) {
  //           // console.log('not found ', floater);
  //           return;
  //         }
  //         floater.style.display = 'flex';
  //
  //
  //         // floater.style.top = 'calc(80vh)';
  //
  //         // console.log('trigger hidden', floater);
  //       },
  //     });
  //   },
  //   [],
  // );

  // useEffect(
  //   ()=>{
  //     const url = 'http://34.87.4.31:8000/workshop/download/summary/1/?order_type=101&order_of=gomechanic&is_download=1';
  //     fetch(url).then(
  //       async res => console.log(await res.text())
  //     ).catch(
  //       console.error
  //     )
  //   }
  // ,[])

  // console.log('is phonepe request ', props.isPhonePeRequest);
  const nonLoginLoader = () => (props.user && props.user.is_logged_in ? null : <FullPageLoader />);
  const [load, setLoad] = useState(false);
  const [workshopData, setWorkshopData] = useState([]);

  useEffect(() => {
    const {longitude, latitude, id} = props.city || {};
    fetchWorkshopsNearYou({
        latitude, longitude, id
    }
      ).subscribe((res) => {
          if(res.data) {
           setWorkshopData(res.data.data);
          }
        }, (err) => {
          console.error(err);
        })
  },[props.city]);

  const getTopWorkshopsWithImages = (numOfWorkshops) => {
    let workshopsWithImages = [];
    for(let workshop of workshopData){
      if(workshopsWithImages.length >= numOfWorkshops)
        break;
      if(workshop.master_image)
        workshopsWithImages.push(workshop);
    }    
    if(workshopData.length >= 4) {
      const lastImage = {
        master_image: "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/locate-us.png", 
        count: workshopData && workshopData.length - 3
      }
      workshopsWithImages = [...workshopsWithImages, lastImage]
    }
    return workshopsWithImages;
  }

  return (
    <>
      {showPopup ? (
        <Popup
          hide={() => {
            setShowPopup(false);
          }}
        />
      ) : null}

      <>
        {load ? <FullPageLoader /> : null}
      </>

      {/* {props.isPhonePeRequest && nonLoginLoader()} */}
      <div className={s.homepageContainer} style={props?.context?.pathname === '/not-found' && props.device?.toLowerCase() === 'mobile' ? {display: 'unset'} : null}>
        <HideOnMobile noParent>
          <PcHomeForm loader={setLoad} />
        </HideOnMobile>

        <HideOnTabPC>
          {/* <div>
            <img className={s.atmaNirbhar} src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/emergency/resuming%20operations-07-min.jpg" />
          </div> */}
          <img style={{ width: '100%', height: 'calc(100vw / 2.06)', objectFit: 'cover' }} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/404%20page/MOBILE.png" alt="404" />
          {/* <CarouselNew2 mobileHomepage arrow bullets={false} /> */}
          {/*          <div className="homepageCarousel"> */}
          {/*            <CarouselNew> */}
          {/*              { */}
          {/*              //   [ */}
          {/*              //   '/graphics/carousel-images-new/CAROUSEL-TEST.jpg', */}
          {/*              //   '/graphics/carousel-images/10-_-off-on-detailing.jpg', */}
          {/*              //   '/graphics/carousel-images/accidental-insurance.jpg', */}
          {/*              //   '/graphics/carousel-images/battery-prpblem.jpg', */}
          {/*              //   '/graphics/carousel-images/car-ac-service.jpg', */}
          {/*              //   '/graphics/carousel-images/doorstep-repair.jpg', */}
          {/*              //   '/graphics/carousel-images/free-car-inspection.jpg', */}
          {/*              //   '/graphics/carousel-images/labour-free.jpg', */}
          {/*              //   '/graphics/carousel-images/make-your-car-brand-new.jpg', */}
          {/*              //   '/graphics/carousel-images/worn-out-tyres.jpg', */}
          {/*              // ] */}
          {/*                [ */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/battery.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/car-ac.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/car-service.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/detailing.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/free-car-inspection.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/insurance.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/labour-free.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/paint.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/windshield.jpg', */}
          {/*                  'https://gomechprod.blob.core.windows.net/gm-retail-app/banners-minified/wornout.jpg', */}

          {/*                ].map((img) => ( */}
          {/*                  <div className={s.imageObjectWrapper} itemScope itemType="https://schema.org/ImageObject"> */}
          {/*                    <img loading="lazy" className={s.carouselNewImage} src={img} itemProp="contentUrl" /> */}
          {/*                  </div> */}
          {/*                )) */}
          {/* } */}
          {/*            </CarouselNew> */}
          {/*          </div> */}
          {/* <Carousel */}
          {/*  whiteButtons */}
          {/*  slideCustomStyle={{ */}
          {/*    // height: '225px', */}
          {/*  }} */}
          {/*  slideShowCustomStyle={{ */}
          {/*    margin: '0rem', */}
          {/*  }} */}
          {/*  // inActiveIcon={InActiveIcon} */}
          {/*  // autoSlideTimer={1500} */}
          {/*  // activeIcon={ActiveIcon} */}
          {/*  // styleForSliderIndicator={{ */}
          {/*  //   marginTop: '-1.5625rem', */}
          {/*  //   marginRight: 'auto', */}
          {/*  //   marginLeft: '3.125rem', */}
          {/*  // }} */}
          {/*  leftBtn={LeftBtn} */}
          {/*  rightBtn={RightBtn} */}

          {/*  images={[ */}
          {/*    '/graphics/carousel-images/10-_-off-on-car-service.jpg', */}
          {/*    '/graphics/carousel-images/10-_-off-on-detailing.jpg', */}
          {/*    '/graphics/carousel-images/accidental-insurance.jpg', */}
          {/*    '/graphics/carousel-images/battery-prpblem.jpg', */}
          {/*    '/graphics/carousel-images/car-ac-service.jpg', */}
          {/*    '/graphics/carousel-images/doorstep-repair.jpg', */}
          {/*    '/graphics/carousel-images/free-car-inspection.jpg', */}
          {/*    '/graphics/carousel-images/labour-free.jpg', */}
          {/*    '/graphics/carousel-images/make-your-car-brand-new.jpg', */}
          {/*    '/graphics/carousel-images/worn-out-tyres.jpg', */}
          {/*  ]} */}
          {/* /> */}
        </HideOnTabPC>

        <HideOnMobile
          customStyle={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            gridRowStart: 1,
          }}
        >
          <div className={styles.PcHomeMainImage}>
            <Page404PcHeader />
          </div>
        </HideOnMobile>

        <div className={s.HomepageBody} style={props?.context?.pathname === '/not-found' && props.device?.toLowerCase() === 'mobile' ? {marginLeft: '0'} : null}>
          <HideOnMobile noParent>
            <PcHomepageNavigationBar />
          </HideOnMobile>

          {/* <div style={{ */}
          {/*  position: 'fixed', */}
          {/*  width: '20.9rem', */}
          {/*  bottom: '15px', */}
          {/*  left: '0', */}
          {/*  margin: '0 1.125rem', */}
          {/*  overflow: 'hidden', */}
          {/* }} */}
          {/* > */}
          {/*  <div */}
          {/*    role="button" */}
          {/*    className={classNames( */}
          {/*      s.containerBox, */}
          {/*      s.redBox, */}
          {/*      formValid ? null : null, */}
          {/*      s.floater, */}
          {/*    )} */}
          {/*    id="floater" */}
          {/*    style={{ */}
          {/*      margin: 0, */}
          {/*      transition: 'top 1s', */}
          {/*      display: 'none', */}
          {/*    }} */}
          {/*    onClick={() => { */}
          {/*      if (typeof window !== 'undefined') { */}
          {/*        window.scrollTo(0, 0); */}
          {/*      } */}
          {/*    }} */}
          {/*  > */}
          {/*    <span>CHECK PRICE</span> */}
          {/*  </div> */}
          {/* </div> */}

          <HideOnTabPC>
            <h1
              style={{
                margin: '0.6rem 0',
                fontSize: '1.1rem',
                display: 'flex',
                justifyContent: 'center',
                // marginTop: '10px',
              }}
            >
              The Best Car Services Await You
            </h1>

            <div
              style={{
                // marginTop: '1.6rem',
                marginBottom: props.isPhonePeRequest ? '14px' : '0',
              }}
            >
              <div
                className={s.containerBox}
                onClick={() => {
                  retailSelectCarEvent(props?.user?.id , '404 page');
                  SetCarSelectorShow(true);
                }}
              >
                <span>
                  {props.cardetails?.brand
                  && props.cardetails?.model
                  && props.cardetails?.fuel
                    ? `${getBrandName(props)} ${getModelName(
                      props,
                    )}, ${getFuelName(props)}`
                    : 'SELECT YOUR CAR'}
                </span>
                {props.cardetails?.brand
                  && props.cardetails?.model
                  && props.cardetails?.fuel ? 
                    <img 
                      loading="lazy"
                      src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/check%20icon.png" 
                      alt="check" 
                    /> : 
                  (
                    <div className={s.dotWrap}>
                      <i className={s.dot}></i>
                    </div>
                )}
                {/* <img
                  loading="lazy"
                  src="/icons/ic_expand_more.svg"
                  alt="Select your car"
                /> */}
              </div>
              {props.isCountry === 'India' || props.isMalaysiaRequest ?
              (!props.isPhonePeRequest && (
                <div
                  className={s.containerBox}
                  style={{
                    marginTop: '14px',
                    marginBottom: '14px',
                  }}
                >
                  <input
                    type="tel"
                    placeholder="ENTER MOBILE NUMBER"
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                  {props.cardetails?.brand
                  && props.cardetails?.model
                  && props.cardetails?.fuel ? 
                  (mobile ? 
                    <img 
                      loading="lazy"
                      src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/check%20icon.png" 
                      alt="check" 
                    /> : (
                      <div className={s.dotWrap}>
                        <i className={s.dot}></i>
                      </div> )) :
                  null}
                </div>
              )) : null}
            </div>

            {/* <Link to="/services"> */}
            <div
              onClick={() => {
                try {
                  tapCheckPriceGtmEvent(
                  props?.user?.id, 'home page',
                  props.cardetails?.fuel.name,
                  props.cardetails?.model.name,
                  props.cardetails?.model.id,
                  props.cardetails?.brand.name,
                  props.cardetails?.brand.id,
                  mobile,
                );
                }catch (e){

                }

                if (props.isCountry === 'India') {
                  if ((isNaN(mobile) || mobile.length !== 10)) {
                    props.action(setToastConfigAction({ show: true, text: 'Please enter your phone number.' }));
                    return;
                  }
                } else if(props.isMalaysiaRequest) {
                  if ((isNaN(mobile) || !(mobile.length == 9 || mobile.length == 10 || mobile.length == 11))) {
                    props.action(setToastConfigAction({ show: true, text: 'Please enter your phone number.' }));
                    return;
                  }
                }
                if (
                  props.cardetails?.model
                  && props.cardetails?.brand
                  && props.cardetails?.fuel
                ) {
                  props.action(
                    setActiveServiceType(props.servicesTypesList[0]),
                  );
                  if (!props.lead) {
                    if (!isNaN(mobile) && mobile.length === 10) {
                      const initialQueryString = (props.initialQueryString && props.initialQueryString.length) ? `?${props.initialQueryString}` : null;
                      const utmSource = getQueryStringValue('utm_source', (initialQueryString || ''));
                      createLead(
                        props.cardetails?.fuel.car_type_id,
                        props.city,
                        mobile,
                        props.user.name,
                        utmSource,
                        // 15,
                        // props.isLat,
                        // props.isLong,
                      ).subscribe((res) => {
                        if (res.status) props.action(setLeadAction(res.data));
                      }, console.error);
                    }
                  }
                  setLoad(true);
                  autoUtmHistory(history).push(
                    generate({
                      city: props.city,
                      // subCity: props.region,
                      subCity: props.selectedRegion || null,
                      serviceType: props.servicesTypesList[0],
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel: props.cardetails?.fuel,
                    }),
                  );
                } else {
                  setToastConfig({
                    ...toastConfig,
                    showTick: false,
                    show: true,
                    text: 'Please select your car.',
                  });
                }
              }}
              className={classNames(
                s.containerBox,
                s.redBox,
                formValid ? null : null,
                // s.floater,
              )}
              style={props.isCountry === 'India' || props.isMalaysiaRequest ? null: {
                marginTop: '14px',
                position: 'sticky',
              }}
            >
              <span>CHECK PRICES FOR FREE</span>
            </div>

            {/* </Link> */}

            <Rating />
          </HideOnTabPC>

          <HomepageSection
            tag="h2"
            title="Our Services"
            htmlId={HomepageSections.OUR_SERVICES}
          >
            <div className={s.servicesDescription}>
              <TopContent />
            </div>

            <HideOnMobile>
              <div
                className={s.servicesContainer}
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
              >
                {props.servicesTypesList.map((service) => {
                  if (service && service.allowCity && service.allowCity.length) {
                    if (!service.allowCity.includes(props.city?.id)) return <></>
                  }
                  return (
                    <Link
                      itemProp="url"
                      onClick={(e) => {
                        e.preventDefault();
                        setLoad(true);
                        clickCategoryBtnEvent(
                          props?.user?.id,
                          'homepage',
                          service.name,
                          service.id,
                          props?.isAMCUser,
                          props?.cardetails?.model?.id,
                        );
                        if(service && service.is_miles === true) {
                          autoUtmHistory(history).push(`/${service.slug}`);
                        }
                        else {
                          props.action(setActiveServiceType(service));
                          autoUtmHistory(history).push(
                            `${generate({
                              city: props.city,
                              subCity: props.selectedRegion,
                              serviceType: service,
                              brand: props.cardetails?.brand,
                              model: props.cardetails?.model,
                              fuel: props.cardetails?.fuel,
                            })}`,
                          );
                          viewItemListGtmEvent(
                            props?.user?.id,
                            service.name,
                            service.id,
                            props?.cardetails?.model?.id,                          
                          );
                        }
                      }}
                      key={service.id}
                      to={service && service.is_miles === true ? service.slug : `${props.apiEndpoints.frontend_serverWithProtocol
                        }${generate({
                          city: props.city,
                          subCity: props.selectedRegion,
                          serviceType: service,
                          brand: props.cardetails?.brand,
                          model: props.cardetails?.model,
                          fuel: props.cardetails?.fuel,
                        })}`}
                      style={{
                        justifySelf: 'center',
                        textDecoration: 'none',
                      }}
                    >
                     <div className={s.desktopNewTagsContainer}>
                      {service.new_tag && <div className={s.newTagsCategory}>{service.new_tag}</div>}
                        <div className={service.new_tag ? classNames(s.newTagServiceItemDesk, s.serviceItem) : s.serviceItem}>
                          <img
                            loading="lazy"
                            src={service.selectedIcon}
                            alt={`${service.name} ${props.city?.name}`}
                          />
                          <span itemProp="name">{service.name}</span>
                        </div>
                    </div>
                    </Link>
                  )
                }
                )}
                {/* <Link to="/accessories">
                  <div className={s.serviceItem}>
                    <img loading="lazy" src="https://via.placeholder.com/90.png?text=Accessories" alt="accessories" />
                    <span itemProp="name">Auto Accessories</span>
                  </div>
                </Link> */}
              </div>
            </HideOnMobile>

            <HideOnTabPC>
              <div
                className={s.servicesContainerMob}
                itemScope
                itemType="http://schema.org/SiteNavigationElement"
              >
                {props.servicesTypesList.map((service) => {
                  if (service && service.allowCity && service.allowCity.length) {
                    if (!service.allowCity.includes(props.city?.id)) 
                    return (<div className={s.dummyServiceItemURL}>
                      <div className={s.serviceItemMob}></div>
                    </div>)
                  }
                  return (
                    <Link
                      itemProp="url"
                      className={props.isMalaysiaRequest ? s.mlyServiceItemURL : s.serviceItemURL}
                      onClick={(e) => {
                        e.preventDefault();
                        setLoad(true);
                        clickCategoryBtnEvent(
                          props?.user?.id,
                          'homepage',
                          service.name,
                          service.id,
                          props?.isAMCUser,
                          props?.cardetails?.model?.id,
                        );
                        if(service && service.is_miles === true) {
                          autoUtmHistory(history).push(`/${service.slug}`);
                        }
                        else {
                          props.action(setActiveServiceType(service));
                          autoUtmHistory(history).push(
                            `${generate({
                              city: props.city,
                              subCity: props.selectedRegion,
                              serviceType: service,
                              brand: props.cardetails?.brand,
                              model: props.cardetails?.model,
                              fuel: props.cardetails?.fuel,
                            })}`,
                          );
                          viewItemListGtmEvent(
                            props?.user?.id,
                            service.name,
                            service.id,
                            props?.cardetails?.model?.id,                          
                          );
                        }
                      }}
                      key={service.id}
                      to={(service && service.is_miles === true) ? service.slug : `${props.apiEndpoints.frontend_serverWithProtocol
                        }${generate({
                          city: props.city,
                          subCity: props.selectedRegion,
                          serviceType: service,
                          brand: props.cardetails?.brand,
                          model: props.cardetails?.model,
                          fuel: props.cardetails?.fuel,
                        })}`}
                      style={{
                        justifySelf: 'center',
                        textDecoration: 'none',
                      }}
                    >
                      {service.new_tag && <div className={s.newTagsCategory}>{service.new_tag}</div>}
                      <div className={service.new_tag ? classNames(s.newTagsserviceItemMob, s.serviceItemMob) : s.serviceItemMob}>
                        <img
                          loading="lazy"
                          src={service.selectedIcon}
                          alt={`${service.name} ${props.city?.name}`}
                        />
                        <span 
                          className={s.productName} 
                          itemProp="name"
                        >
                        {service.name}
                        </span>
                      </div>
                    </Link>
                  )
                })}
                {/* <Link to="/accessories">
                  <div className={s.serviceItem}>
                    <img loading="lazy" src="https://via.placeholder.com/90.png?text=Accessories" alt="accessories" />
                    <span itemProp="name">Auto Accessories</span>
                  </div>
                </Link> */}
              </div>
            </HideOnTabPC>
          </HomepageSection>

          <HideOnTabPC>
            <HomepageSection>
            {props.home?.bannerLevel2?<>
              <div onClick={(e) => {
                e.preventDefault();
                props.action(setActiveServiceType(props.servicesTypesList[props.home?.bannerLevel2?.serviceType]));
                autoUtmHistory(history).push(`${generate({
                  serviceType: props.servicesTypesList[props.home?.bannerLevel2?.serviceType],
                  city: props.city,
                  brand: props.cardetails?.brand,
                  model: props.cardetails?.model,
                  fuel: props.cardetails?.fuel,
                })}`);
              }}>
                <PlaceholderImage
                  placeHolderImage={carouselPlaceholderImage}
                  loading="lazy"
                  src={props.home?.bannerLevel2?.image}
                  className={s.batteryBanner}
                  alt="Battery"
                />
              </div>
              </>
              :null }
            </HomepageSection>
          </HideOnTabPC>

          {/* <HomepageSection
            tag="h2"
            title="Popular Services"
            htmlId={HomepageSections.POPULAR_SERVICES}
          >
            <PopularService />
          </HomepageSection> */}

          {/* <HomepageSection>
            <InusuranceProgram />
          </HomepageSection> */}

          {props.isNigerianRequest ? null : (
            <HomepageSection 
              tag="h2" 
              title={props.home?.monsoonData?.title} 
              htmlId={HomepageSections.GOMECHANIC_MANSOON_SHIELD}
            >
              <div className={s.monsoonSubHead}>{props.home?.monsoonData?.subtitle}</div>
              {/* <HideOnMobile> */}
                <MonsoonService />
              {/* </HideOnMobile> */}
            </HomepageSection>
          )}
          {/* <HomepageSection>
            <BeatThePrice />
          </HomepageSection> */}

          {props.isNigerianRequest || props.isMalaysiaRequest ? null : (
            <HomepageSection tag="h2" title="Choose the Workshop Near You" htmlId={HomepageSections.WORKSHOP_NEAR_YOU}>
               <div className={s.workshopSubHead}>{"Schedule a Pickup Today!"}</div>
               {getTopWorkshopsWithImages(3).length < 1 ? 
                <>
                  <HideOnMobile>
                    <BottomSlider scrollButtons
                      popularServices={1}
                      content={Array(3).fill(<CarouselSkeleton/>)}      
                    />  
                  </HideOnMobile>
                  <HideOnTabPC>
                    <BottomSlider
                      popularServices={1}
                      content={Array(3).fill(<CarouselSkeleton/>)}      
                    />  
                  </HideOnTabPC>
                </>
               : 
               <>
                <HideOnMobile>
                  <BottomSlider
                    scrollButtons
                    popularServices={1}
                    content={getTopWorkshopsWithImages(3).map((data) => (
                      <WorkShopNearYou 
                        cardetails={props.cardetails} 
                        articleImage={data?.master_image} 
                        servicesTypesList={props.servicesTypesList} 
                        popularServices={1} 
                        workshopData={workshopData}
                        count = {data.count || 0}
                      />
                    ))}
                  />
                </HideOnMobile>
                <HideOnTabPC>
                  <BottomSlider
                    popularServices={1}
                    content={getTopWorkshopsWithImages(3).map((data) => (
                    <WorkShopNearYou 
                      cardetails={props.cardetails} 
                      articleImage={data?.master_image} 
                      servicesTypesList={props.servicesTypesList} 
                      popularServices={1} 
                      workshopData={workshopData}
                      count = {data.count || 0}
                    />
                    ))}
                  />
                </HideOnTabPC>
               </>
              } 
            </HomepageSection> 
          )}

          {props.isNigerianRequest ? null : (
            <HomepageSection tag="h2" title="Introducing GoMechanic Luxe" htmlId={HomepageSections.GOMECHANIC_LUXE}>
              <div className={s.tradeMark}>&#8482;</div>
              <HideOnMobile>
                <BottomSlider
                  style={{ marginTop: '-30px' }}
                  scrollButtons
                  popularServices={1}
                  content={props.home?.luxeServicesImages.map((data) => (
                    <LuxeService
                      articleImage={data.image}
                      popularServices={1}
                      serviceText={data.heading}
                      serviceTextBottom={data.subHeading}
                      serviceTypeIndex={data.id}
                      luxeServiceBrand={data.brand}
                    />
                  ))}
                />
              </HideOnMobile>
              <HideOnTabPC>
                <BottomSlider
                  style={{ marginTop: '-35px' }}
                  popularServices={1}
                  content={props.home?.luxeServicesImages.map((data) => (
                    <LuxeService
                      articleImage={data.image}
                      popularServices={1}
                      serviceText={data.heading}
                      serviceTextBottom={data.subHeading}
                      serviceTypeIndex={data.id}
                      luxeServiceBrand={data.brand}
                    />
                  ))}
                />
              </HideOnTabPC>
            </HomepageSection>
          )}
          {props.isNigerianRequest ? (
            <HomepageSection
              tag="h2"
              title="How GoMechanic45 works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >
              <HideOnMobile>
                <HowGomechWorks />
              </HideOnMobile>

              <HideOnTabPC>
                <WorksMob />
              </HideOnTabPC>
            </HomepageSection>
          ) : (
            <HomepageSection
              tag="h2"
              title="How GoMechanic works?"
              htmlId={HomepageSections.HOW_GOMECH_WORKS}
            >
              <HideOnMobile>
                <HowGomechWorks />
              </HideOnMobile>

              <HideOnTabPC>
                <WorksMob />
              </HideOnTabPC>
            </HomepageSection>
          )}

          {props.isNigerianRequest ? (
            <HomepageSection
              tag="h2"
              title="GoMechanic45 Benefits"
              htmlId={HomepageSections.GOMECH_BENEFITS}
            >
              <div style={{ marginTop: '1.875rem' }}>
                <HideOnMobile>
                  <GomechBenefitsPC />
                </HideOnMobile>
                <HideOnTabPC>
                  <GoMechanicBenefits />
                </HideOnTabPC>
              </div>
            </HomepageSection>
          ) : (
            <HomepageSection
              tag="h2"
              title="GoMechanic Benefits"
              htmlId={HomepageSections.GOMECH_BENEFITS}
            >
              <div style={{ marginTop: '1.875rem' }}>
                <HideOnMobile>
                  <GomechBenefitsPC />
                </HideOnMobile>
                <HideOnTabPC>
                  <GoMechanicBenefits />
                </HideOnTabPC>
              </div>
            </HomepageSection>
          )}

          {/* {!props.isPhonePeRequest && ( */}
          {/* <HideOnPC> */}
          {/*  <RatingReviews /> */}
          {/* </HideOnPC> */}
          <div className={s.sectioLine} />
          <HideOnMobileTab>
            <RatingReviews style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }} />
          </HideOnMobileTab>

          {/* <HomepageSection
            tag="h2"
            title="What customers say about us?"
            htmlId={HomepageSections.CUSTOMER_HOME_VIDEO}
          >
             <HomeVideo />
          </HomepageSection> */}

          <HomepageSection
            tag="h2"
            title="Rating & Reviews"
            htmlId={HomepageSections.RATING_REVIEWS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              {/* <div style={{ marginTop: '2rem' }}> */}
              {/* <HomepageCarousel */}
              {/*  customize={{ */}
              {/*    minWidth:(props.device === deviceTypes.DESKTOP)?'51%':'100%' */}
              {/*  }} */}
              {/*  items={props.reviewsCurrentCity.map((review) => <UserReviews {...review} />)} */}
              {/* /> */}
              {/* <CarouselNew> */}
              {/*  {props.reviewsCurrentCity && props.reviewsCurrentCity.map((review) => <UserReviews {...review} />)} */}
              {/* </CarouselNew> */}

              <ReviewsCarousel />

              {/* <HideOnMobile>
                  <ReviewsHomepage />
                </HideOnMobile>
                <HideOnTabPC>
                  <div style={{ width: '100%' }}>
                    <ReviewsHomepageMobile />
                  </div>
                </HideOnTabPC> */}
              {/* </div> */}
            </div>
          </HomepageSection>
          {/* )} */}

          {/* <HomepageSection
            tag="h3"
            title="Our Partners"
            htmlId={HomepageSections.OUR_PARTNERS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              <Partners />
            </div>
          </HomepageSection> */}

          {/* <HomepageSection
            title="Fleet of Cars?"
            htmlId={HomepageSections.FLEET_OF_CARS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              <HideOnTabPC>
                <span className={s.font13Normal}>
                  Got a fleet of cars? Avail affordable fleet maintenance
                  services. Best annual maintenance packages for fleet cars by
                  GoMechanic is all you need!
                </span>

                <a
                  style={{
                    textDecoration: 'none',
                  }}
                  href="tel:8398970970"
                >
                  <CallButton
                    text="8398970970"
                    customizeButton={{
                      boxShadow: '0 0.25rem 0.3125rem 0 rgba(0, 0, 0, 0.12)',
                      backgroundColor: '#071c2c',
                      padding: '0.625rem 0.75rem',
                    }}
                  />
                </a>
              </HideOnTabPC>
              <HideOnMobile>
                <GiveRequirements
                  toFill="Got a fleet of cars? Avail affordable fleet maintenance services. Best annual maintenance packages for fleet cars by GoMechanic is all you need!"
                  text="8398970970"
                  phone
                />
              </HideOnMobile>
            </div>
          </HomepageSection> */}
          {/* <HideOnMobile>
            <HomepageSection
              tag="h3"
              title="Looking for Custom Repairs?"
              htmlId={HomepageSections.LOOKING_FOR_CUSTOM}
            >
              <div style={{ marginTop: '1.875rem' }}>

                <GiveRequirements
                  toFill={props.home.custom_repairs_text.replace(
                    '{{city.name}}',
                    props.city?.name,
                  )}
                  text="Give Requirements"
                />
              </div>
            </HomepageSection>
          </HideOnMobile>

          <HideOnTabPC>
            <HomepageSection>
              <div className={s.headingDiv}>
                <img src="/graphics/requirement_car.png" className={s.image} />
                <span className={s.heading}>
                  Looking for custom repairs for your car in
                  {' '}
                  {props.city?.name}
                  ?
                </span>
              </div>

              <div className={s.textDiv}>
                <span className={s.font13Normal}>
                  {props.home.custom_repairs_text.replace(
                    '{{city.name}}',
                    props.city?.name,
                  )}
                </span>
              </div>
              <a
                style={{
                  textDecoration: 'none',
                }}
                href="tel:8398970970"
                onClick={(e) => { e.preventDefault(); setShowPopup(true); }}
              >
                <CallButton
                  text="Give Requirements"
                  callButtonHide
                  customizeButton={{
                    boxShadow: '0 0.25rem 0.3125rem 0 rgba(0, 0, 0, 0.12)',
                    backgroundColor: '#071c2c',
                    padding: '0.625rem 0.75rem',
                  }}
                />
              </a>
            </HomepageSection>
          </HideOnTabPC> */}

          {/* <HomepageSection */}
          {/*  title="Brands we service" */}
          {/*  subtitle="A Big Handful of Them" */}
          {/*  htmlId={HomepageSections.BRANDS_WE_SERVICE} */}
          {/* > */}
          {/*  <div style={{ marginTop: '1.875rem' }}> */}
          {/*    <ImageGrid */}
          {/*      images={props.allBrands.map((brand) => ({ */}
          {/*        src: brand.icon, */}
          {/*        alt: brand.name, */}
          {/*      })).filter( */}
          {/*        (brand, i) => i <= 13, */}
          {/*      )} */}
          {/*    /> */}
          {/*  </div> */}
          {/* </HomepageSection> */}

          <HomepageSection
            tag="h2"
            title="Frequently Asked Questions"
            htmlId={HomepageSections.FREQUENTLY_ASKED_QUESTIONS}
          >
            <div style={{ marginTop: '1.875rem' }}>
              <NewFaqAccordion />
            </div>
          </HomepageSection>

          {/* <HomepageSection
          >
            <div style={{ marginTop: '1.875rem', marginLeft: '-14px' }}>
            <HideOnTabPC>
              <AppDownload />
              </HideOnTabPC>
            </div>
          </HomepageSection> */}
          <DisplayWrapper section={displayPossibleSctions.APP_DOWNLOAD}>
            {/* {!props.isPhonePeRequest && !props.isNigerianRequest && !props.isPaytmRequest && ( */}
            <HomepageSection
              title="Download GoMechanic App"
              tag="h2"
              htmlId={HomepageSections.DOWNLOAD_APP}
            >
              <AppDetails />
            </HomepageSection>
          </DisplayWrapper>
          <HomepageSection
              tag="h2"
              title="Featured Service Buddies"
            >
            <ServiceBuddyCarousel />
          </HomepageSection>
          <DisplayWrapper section={displayPossibleSctions.SERVICE_GUIDE}>
            <HomepageSection
              tag="h2"
              title="GoMechanic Service Guide"
              htmlId={HomepageSections.GOMECH_SERVICE_GUIDE}
            >
              <div
                style={{
                  marginTop: '1.875rem',
                  paddingBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                  marginBottom: props.isPhonePeRequest ? '1.875rem' : '0',
                }}
              >
                <ServicesGuide tableDataFor="home" />
              </div>
            </HomepageSection>
          </DisplayWrapper>
        </div>
      </div>
      {carSelectorShow ? (
        <SelectorMain hide={() => SetCarSelectorShow(false)} />
      ) : null}
      <DisplayWrapper section={displayPossibleSctions.FOOTER}>
        <FooterPage />
      </DisplayWrapper>

      {/* <ManufacturerSelector/> */}
      {/* <BrandSelector/> */}
      {/* <FuelTypeSelector/> */}
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
