/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a54d2d3af23439013ad
 *
 * This file contains code for Gomechanic Benefits Section for PC
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { connect } from 'react-redux';
import s from './css/main.css';
import GomechBenefitItem from './GomechBenefitItem';

const GomechBenefitsPC = (props) => {
  useStyles(s);
  return (
    <>
      <div className={s.container}>
        {props.gomech_benefits?.map((benefit) => (
          <GomechBenefitItem
            key={benefit.title}
            title={benefit.title}
            subtitle={benefit.subtitle}
            image={benefit.image}
          />
        ))}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GomechBenefitsPC);
