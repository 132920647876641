import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/main.css';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';

const ImageGrid = (props) => {
  useStyles(s);
  return (
    <div className={s.gridContainer} style={{ ...props.customStyle }}>
      {props.images.map((image, i) => (
        <PlaceholderImage
          src={image.src}
          key={image.src.slice(-5) + i}
          alt={image.alt}
          className={s.image}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
