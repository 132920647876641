// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ldS3{max-width:230px;max-width:14.375rem;margin:0 .625rem}._3ldS3,._1eawO{display:grid;grid-template-rows:auto;font-family:Gilroy,sans-serif}._1eawO{margin:4px .625rem 0 0}._30Fig{max-width:230px;max-width:14.375rem;min-width:230px;min-width:14.375rem}.BD-Pa{width:230px;width:14.375rem;height:150px;height:9.375rem;-o-object-fit:cover;object-fit:cover}.a0Y_y{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;vertical-align:middle;-ms-flex-align:center;align-items:center}.cSiTm{font-size:15px;font-size:.9375rem;margin-top:-64px;font-weight:800;padding-bottom:8px;text-align:center}._1gjBn,.cSiTm{color:#fff;position:relative}._1gjBn{font-size:12px;font-size:.75rem;font-weight:600;margin-top:-6px}._3Ui8t{display:none}._2MIIe{width:278px;width:17.375rem;height:214px;height:13.375rem;border-radius:8px}._2bPL1{padding:.75rem .75rem .75rem .9375rem;background-color:#fff}._3FTjN{font-size:11px;font-size:.6875rem;letter-spacing:.1px;letter-spacing:.0063rem;color:#4a4a4a;display:grid;grid-template-columns:1fr 1fr;-ms-flex-pack:justify;justify-content:space-between}._25eMk,._3FTjN{font-weight:500;font-stretch:normal;font-style:normal;text-align:left}._25eMk{margin-top:1.375rem;font-size:13px;font-size:.8125rem;letter-spacing:.12px;letter-spacing:.0075rem;color:#083250}._2ZYqE>img,._35ERG>img{width:10px;width:.625rem;height:10px;height:.625rem;margin-right:.25rem}@media only screen and (min-width:700px){._2MIIe{width:20.375rem;height:15.375rem;border-radius:7px;cursor:pointer}._3ldS3{max-width:90vw}.cSiTm{margin-top:-51px}}", ""]);
// Exports
exports.locals = {
	"container": "_3ldS3",
	"padding": "_1eawO",
	"item": "_30Fig",
	"gridImage": "BD-Pa",
	"textDiv": "a0Y_y",
	"capText": "cSiTm",
	"bottomText": "_1gjBn",
	"hide": "_3Ui8t",
	"popularServicesImage": "_2MIIe",
	"articleDetails": "_2bPL1",
	"authorAndDate": "_3FTjN",
	"articleTitle": "_25eMk",
	"author": "_2ZYqE",
	"date": "_35ERG"
};
module.exports = exports;
