import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/Homepage.css';
import DesktopHeaderMain from './DesktopHeaderMain/DesktopHeaderMain';

const Page404PcHeader = (props) => {
  useStyles(s);
  return (
    <>
        {!props.withoutMain && 
            <DesktopHeaderMain 
                style={{
                position: 'unset',
                gridColumn: '1 / 3',
                }}
                noOpacity
                darkBg
                noScroll 
            />
        }
    </>
  );
};

export default Page404PcHeader;
