// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4s5MN{position:inherit;margin:auto -15px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;background:#fff}._2q2Og{display:none;height:180px}._2q2Og>img{height:100%;-o-object-fit:cover;object-fit:cover;max-width:100%;min-width:100vw;max-height:100%}.fDyQY,._3Vd-4{cursor:pointer;position:inherit;margin-top:-100px;margin-left:7px;margin-right:7px;padding:5px;color:#000;font-weight:700;font-size:18px;-webkit-transition:.6s ease;-o-transition:.6s ease;transition:.6s ease;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#fff;height:16px}.fDyQY{right:0}._euiW{font-size:15px;bottom:8px;width:100%;text-align:center}.ao3vJ,._euiW{color:#f2f2f2;padding:8px 12px;position:absolute}.ao3vJ{font-size:12px;top:0}._7BPDb{cursor:pointer;height:5px;width:5px;margin:0 2px}._2hRIa{-webkit-animation-name:_2hRIa;animation-name:_2hRIa}@-webkit-keyframes _2hRIa{0%{opacity:.4}to{opacity:1}}@keyframes _2hRIa{0%{opacity:.4}to{opacity:1}}.t2PNZ{display:block}._3nMrG{display:none}._1hJ9Y{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;width:100%}", ""]);
// Exports
exports.locals = {
	"slideshowContainer": "_4s5MN",
	"mySlides": "_2q2Og",
	"next": "fDyQY",
	"prev": "_3Vd-4",
	"text": "_euiW",
	"numbertext": "ao3vJ",
	"dot": "_7BPDb",
	"fade": "_2hRIa",
	"dBlock": "t2PNZ",
	"dNone": "_3nMrG",
	"navigContainer": "_1hJ9Y"
};
module.exports = exports;
