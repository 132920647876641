import React from 'react';
import { connect } from 'react-redux';
import ImageGrid from '../../ImageGrid/ImageGrid';

const Partners = (props) => (
  <ImageGrid
    customStyle={{
      gridTemplateColumns: 'repeat(auto-fill, minmax(92px, 1fr))',
    }}
    images={props.home?.partnerIcons?.map((img) => ({
      src: `/${img['img']}`,
      alt: `${img['alt']}`,
    }))}
  />
);

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
