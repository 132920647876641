import React from 'react';
import classNames from 'classnames';
import s from './Carousel.css';

export const LeftBtn = (props) => (
  <a onClick={props.leftClickHandler} style={{...props.style}} className={classNames(s.prev)}>
    &#10094;
  </a>
);

export const RightBtn = (props) => (
  <a onClick={props.rightClickHandler} style={{...props.style}} className={classNames(s.next)}>
    &#10095;
  </a>
);
