/*
 Author : Patanjali Kumar

 This component will contain the "Related Blogs" section as in
https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
 */
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './Responsive.css';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import history from '../../../history';
import autoUtmHistory from '../../../_services/autoUtmHistory';

const BlogSection = (props) => {
  useStyles(s);
  return (
    <div className={props.popularServices == 1 ? s.padding : s.container}>


      { props.url
        ? (
          <a href={props.url}>
            <img loading="lazy" target="_blank" src={props.articleImage} className={props.popularServices == 1 ? s.popularServicesImage : s.gridImage} alt="Blog" />
          </a>
        )
        : (
          <img
            loading="lazy"
            src={props.articleImage}
            onClick={(e) => {
              e.preventDefault();
              props.action(setActiveServiceType(props.servicesTypesList[props.serviceTypeIndex]));
              autoUtmHistory(history).push(`${generate({
                serviceType: props.servicesTypesList[props.serviceTypeIndex],
                city: props.city,
                brand: props.cardetails?.brand,
                model: props.cardetails?.model,
                fuel: props.cardetails?.fuel,
              })}`);
            }}
            className={props.popularServices == 1 ? s.popularServicesImage : s.gridImage}
            alt="Blog"
          />
        ) }


      <div className={props.popularServices == 1 ? s.textDiv : s.hide}>
        <div
          className={s.capText}
          style={props.accessoriesBlog ? { backgroundColor: 'black', opacity: '0.7' } : null}
        >
          {props.serviceText}
        </div>
        {props.serviceTextBottom ? (
          <div className={s.bottomText}>{props.serviceTextBottom}</div>
        ) : null}
      </div>

      <div className={props.popularServices == 1 ? s.hide : s.articleDetails}>
        <div className={s.authorAndDate}>
          {props.authorName && (
            <>
              <div className={s.author}>
                <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/B0CC5D66-C6F3-489F-9A74-D483C6B6D729.svg" alt="Author" />
                <span>{props.authorName}</span>
              </div>
            </>
          )}

          {props.publishDate && (
            <>
              <div className={s.date}>
                <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/24CC41AD-3911-4D46-876E-7F3D67A277FE.svg" alt="Date" />
                <span>{props.publishDate}</span>
              </div>
            </>
          )}
        </div>

        <a href={props.url} rel="noopener noreferrer" target="_blank">
          <div className={s.articleTitle}>{props.articleTitle}</div>
        </a>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogSection);
