import { SET_SERVICE_BUDDY_DATA } from '../constants';

export function serviceBuddyAction(value) {
  return {
    type: SET_SERVICE_BUDDY_DATA,
    payload: {
      value,
    },
  };
}
