import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from '../HomepageSection/Benefits.css';

const GoMechanicBenefits = (props) => {
  useStyles(s);

    var popService = props.home?.popularService ?? [];

    if(popService.length < 4)
    return null;
  return (
    <>
       <div className={s.brnadBox}>
         <div className={s.benefitsDiv}>
        <img loading='lazy' src={popService[0]['image']} className={s.img} />
        <div className={s.textAlign}>{popService[0]['heading']}</div>
        </div>
        <div className={s.benefitsDiv}>
        <img loading='lazy' src={popService[1]['image']} className={s.warrentyImg} />
        <div className={s.textAlign}>{popService[1]['heading']}</div>
        </div>
        <div className={s.benefitsDiv}>
        <img loading='lazy' src={popService[2]['image']} className={s.affordableImg} />
        <div className={s.textAlign}>{popService[2]['heading']}</div>
        </div>
        <div className={s.benefitsDiv}>
        <img loading='lazy' src={popService[3]['image']} className={s.pickupImg} />
        <div className={s.textAlign}>{popService[3]['heading']}</div></div>
      </div>
    </>
 ); 
 };

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoMechanicBenefits);
