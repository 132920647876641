// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".feX_P{display:grid;grid-template-columns:repeat(auto-fill,minmax(60px,1fr));-ms-flex-pack:center;justify-content:center;grid-column-gap:1.375rem;grid-row-gap:1.875rem;-ms-flex-align:center;align-items:center}._2_Qfb{-o-object-fit:cover;object-fit:cover;width:100%;max-height:94px;max-height:5.875rem}@media (min-width:1024px){.feX_P{grid-template-columns:repeat(auto-fill,minmax(150px,1fr))}._2_Qfb{-o-object-fit:cover;object-fit:cover;width:auto;max-height:5.875rem}}", ""]);
// Exports
exports.locals = {
	"gridContainer": "feX_P",
	"image": "_2_Qfb"
};
module.exports = exports;
