import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import Glide from '@glidejs/glide';
import ServiceBuddyReviews from './ServiceBuddyReviews';
import { getServiceBuddyApi } from '../../_services/api/getServiceBuddyApi';
import { serviceBuddyAction } from '../../actions/serviceBuddyAction';
import GlideCarousel from '../GlideCarousel/GlideCarousel';

const ServiceBuddyCarousel = (props) => {
  // const [perView, SetPerView] = useState(2);
  // useEffect(() => {
  //   new Glide('#serviceBuddy', {
  //     type: 'carousel',
  //     perView,
  //     autoplay: !props.noAutoplay ? 5000 : null,
  //     breakpoints: {
  //       768: {
  //         perView: 1,
  //       },
  //     },
  //   }).mount();
  // }, []);

  useEffect(() => {
    getServiceBuddyApi(props.city && props.city?.sitemap_name).subscribe((res) => {
      if(!!res && res.success == true) {
        props.dispatch(serviceBuddyAction(res.data));
      }
    }, (error) => {
      console.error(error);
    });
  },[props.city]);
  
  let reviews = [];
  reviews = props.serviceBuddyData && props.serviceBuddyData.map((review) => <ServiceBuddyReviews {...review} workshop={props.workshop} />);

  return (
    <>
    <GlideCarousel containerId={'serviceBuddy'} reviews={reviews}/>
      {/* <div className="glide" id="serviceBuddy">
        <div
          className="glide__track"
          data-glide-el="track"
        >
          <ul className="glide__slides">
            {reviews.map((review) => (
              <li className="glide__slide">
                {review}
              </li>
            ))}
          </ul>
        </div>

        <div>
            <div className="glide__bullets reviewsBullets" data-glide-el="controls[nav]">
                {reviews.map(
                  (review, index) => <button className="glide__bullet userReviewsBullet" data-glide-dir={`=${index}`} />,
                )}
            </div>
        </div>
          
      </div> */}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBuddyCarousel);
