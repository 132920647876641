// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3eroC{min-height:750px;min-height:46.875rem;min-width:100%;background:url(https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/404%20page/DESKTOP.png) no-repeat;background-size:cover}", ""]);
// Exports
exports.locals = {
	"PcHomeMainImage": "_3eroC"
};
module.exports = exports;
