import React, {useState} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/Responsive.css';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from './carouselPlaceholder.png';
import WorkShopMapPopup from './WorkshopMapPopup/workshopMapPopup';
import classNames from 'classnames';

const WorkShopNearYou = (props) => {
  useStyles(s);

  const [showPopup, setShowPopup] = useState(false); 
  
  const hide=() => {
    setShowPopup(false);
    const z = document.getElementsByTagName('body');
    z[0].style.overflow = 'scroll';
  }

  return (
    <>
    {showPopup ? 
      <WorkShopMapPopup 
        hide={hide} 
        workshopData={props.workshopData} 
        cardetails={props.cardetails} 
        servicesTypesList={props.servicesTypesList}
      /> 
      : 
      null
    }
    <div 
      onClick = {() => setShowPopup(true)}
      className={props.popularServices == 1 ? classNames(s.padding,s.imageLocate): classNames(s.container,s.imageLocate)}
    >
      <PlaceholderImage
        placeHolderImage={carouselPlaceholderImage}
        loading="lazy"
        src={props.articleImage}
        className={props.popularServices == 1 ? s.popularServicesImages : s.gridImage}
        alt="workshop"
      />
      {!!props.count &&
        <div className={s.cityCountParent}>
          <p className={s.cityCount}> +{props.count} more </p>
          <p className={s.locateUs}>LOCATE US</p>
        </div>
      }
    </div>
    </>
  );
};

export default WorkShopNearYou;