/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL :
 *
 * This file contains code for
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */

import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { connect } from 'react-redux';
import s from './css/main.css';

const GomechBenefitItem = (props) => {
  useStyles(s);
  return (
    <>
      <div className={s.benefitItem}>
        <img loading='lazy' className={s.benefitImage} src={props.image} alt="Benefits" />
        <div className={s.benefitTitleSubtitle}>
          <div className={s.title}>{props.title}</div>
          <div className={s.subtitle}>
            {props.subtitle}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GomechBenefitItem);
