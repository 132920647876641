/*
 Author : Patanjali Kumar

 This component will contain the "Related Blogs" section as in
https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
 */
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/Responsive.css';
import { setActiveServiceType } from '../../actions/serviceType';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import history from '../../history';
import setUserCar from '../../actions/cardetails';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from './carouselPlaceholder.png';
import autoUtmHistory from '../../_services/autoUtmHistory';

const LuxeService = (props) => {
  useStyles(s);
  return (
    <div
      className={props.popularServices == 1 ? s.padding : s.container}
      onClick={(e) => {
        e.preventDefault();
        props.action(setActiveServiceType(props.servicesTypesList[0]));
        props.action(setUserCar({
          brand: props.luxeServiceBrand,
          model: null,
          fuel: null,
        }));
        autoUtmHistory(history).push(`${generate({
          city: props.city,
          subCity: props.selectedRegion,
          serviceType: props.servicesTypesList[0],
          brand: props.luxeServiceBrand,
        })}`, {
          showCarSelection: true,
        });
      }}
    >
      <PlaceholderImage
        placeHolderImage={carouselPlaceholderImage}
        loading="lazy"
        src={props.articleImage}
        className={props.popularServices == 1 ? s.popularServicesImage : s.gridImage}
        alt="Luxe"
      />


      <div className={props.popularServices == 1 ? s.textDiv : s.hide}>
        <div className={s.capText}>{props.serviceText}</div>
        <div className={s.bottomText}>{props.serviceTextBottom}</div>
      </div>

      <div className={props.popularServices == 1 ? s.hide : s.articleDetails}>
        <div className={s.authorAndDate}>
          {props.authorName && (
            <>
              <div className={s.author}>
                <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/B0CC5D66-C6F3-489F-9A74-D483C6B6D729.svg" alt="Author" />
                <span>{props.authorName}</span>
              </div>
            </>
          )}

          {props.publishDate && (
            <>
              <div className={s.date}>
                <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/24CC41AD-3911-4D46-876E-7F3D67A277FE.svg" alt="Date" />
                <span>{props.publishDate}</span>
              </div>
            </>
          )}
        </div>

        <a href={props.url} rel="noopener noreferrer" target="_blank">
          <div className={s.articleTitle}>{props.articleTitle}</div>
        </a>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LuxeService);
