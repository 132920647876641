/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import Glide from '@glidejs/glide';
import UserReviews from '../UserReviews/UserReviews';
// import HideOnMobile from '../../_wrappers/HideOnMobile';
// import HideOnTabPC from '../../_wrappers/HideOnTabPC';
import GlideCarousel from '../GlideCarousel/GlideCarousel';

const ReviewsCarousel = (props) => {
  // const [perView, SetPerView] = useState(props.bikeEnquiry ? 3 : props.perView || 2);
  // useEffect(() => {
  //   new Glide('#reviewCar', {
  //     type: 'carousel',
  //     perView,
  //     autoplay: !props.noAutoplay ? 5000 : null,
  //     breakpoints: {
  //       768: {
  //         perView: 1,
  //       },
  //     },
  //   }).mount();
  // }, []);

  let reviews = [];
  if (props.carModalPage) {
    reviews = props.accessoriesReviews ? props.accessoriesReviews.map(
      (review) => (
        <UserReviews
          {...review}
          carModalPage
          restrictContent={props.restrictContent}
          workshop={props.workshop}
          whiteBg={!!props.whiteBg}
        />
      ),
    ) : [];
  } else {
    reviews = props.bikeEnquiry ? props.bikeenquirs?.reviews && props.bikeenquirs?.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />)
      : props.AdvertisePage ? props.home?.youtubeads?.reviews && props.home?.youtubeads?.reviews?.map((review) => <UserReviews {...review} workshop={props.workshop} />) : props.reviewsCurrentCity && props.reviewsCurrentCity.map((review) => <UserReviews {...review} workshop={props.workshop} />);
  }
  return (
    <>
    <GlideCarousel containerId={'reviewCar'} reviews={reviews}/>
      {/* <div className="glide" id="reviewCar">
        <div
          className="glide__track"
          data-glide-el="track"
        >
          <ul className="glide__slides">
            {reviews.map((review) => (
              <li className="glide__slide">
                {review}
              </li>
            ))}
          </ul>
        </div>

        { props.bikeEnquiry
          ? (
            <>
              <HideOnMobile>
                <div>
                  <div className="glide__bullets reviewsBullets" data-glide-el="controls[nav]">
                    {reviews.slice(0, 2).map(
                      (review, index) => <button className="glide__bullet userReviewsBullet" data-glide-dir={`=${index}`} />,
                    )}
                  </div>
                </div>
              </HideOnMobile>
              <HideOnTabPC>
                <div>
                  <div className="glide__bullets reviewsBullets" data-glide-el="controls[nav]">
                    {reviews.slice(0, 3).map(
                      (review, index) => <button className="glide__bullet userReviewsBullet" data-glide-dir={`=${index}`} />,
                    )}
                  </div>
                </div>
              </HideOnTabPC>
            </>
          )
          : (
            <div>
              <div className="glide__bullets reviewsBullets" data-glide-el="controls[nav]">
                {reviews.map(
                  (review, index) => <button className="glide__bullet userReviewsBullet" data-glide-dir={`=${index}`} />,
                )}
              </div>
            </div>
          )}
      </div> */}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsCarousel);
