import React from 'react';
import Layout from '../../components/Layout';
import Page404 from '../../components/Page404/Page404';

const title = 'Page 404';

function action(context) {
  return {
    chunks: ['page404'],
    title,
    component: (
      <Layout marketingHeaderShow>
        <Page404 context={context}/>
      </Layout>
    ),
    chatbotControl: 'show',
  };
}


export default action;
