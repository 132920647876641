/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './Carousel.css';
import { ActiveIcon, InActiveIcon } from './ActiveInactiveIcons';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from './carouselPlaceholder.png';

const Carousel = (props) => {
  // return <h1>hello</h1>
  useStyles(s);
  const [slideToShow, setSlideToShow] = useState(0);

  const LeftBtn = props.leftBtn;
  const RightBtn = props.rightBtn;

  const ActiveIcon = props.activeIcon;
  const InActiveIcon = props.inActiveIcon;

  const images = props.images || [
    'https://www.w3schools.com/howto/img_nature_wide.jpg',
    'https://cdn.zeplin.io/5da0780780829f083448125a/assets/4B7244A1-E091-409F-A5AB-2DC66AC4EEAE.png',
    'https://www.w3schools.com/w3css/img_lights.jpg',
  ];

  const autoSlideStart = (slideToShow) => setTimeout(
    () => {
      setSlideToShow(slideToShow === images.length - 1 ? 0 : slideToShow + 1);
    }, props.autoSlideTimer,
  );
  useEffect(() => {
    if (props.autoSlideTimer) {
      const interval = autoSlideStart(slideToShow);
      // setTimeout(() => {
      //   setSlideToShow(slideToShow === images.length - 1 ? 0 : slideToShow + 1);
      // }, props.autoSlideTimer);
      return () => {
        if (interval) clearTimeout(interval);
      };
    }
  }, [props.autoSlideTimer, slideToShow]);

  const additionalStyleForLeftRightButtons = props.leftRightButtonStyle || {};
  return (
    <>
      <div
        className={classNames(s.slideshowContainer)}
        style={{ ...props.slideShowCustomStyle }}
        onClick={
        (event) => event.stopPropagation()
      }
      >
        {/* <!-- Full-width images with number and caption text --> */}

        {images.map((image, index) => (
          <div
            className={classNames(
              s.mySlides,
              s.fade,
              slideToShow === index ? s.dBlock : s.dNone,
            )}
            style={{ ...props.slideCustomStyle }}
          >
            <PlaceholderImage placeHolderImage={carouselPlaceholderImage} loading="lazy" src={image} style={{ width: '100%', objectFit: 'cover', ...props.SlideImgStyle }} alt="Carousel" />
          </div>
        ))}

        {/* <!-- Next and previous buttons --> */}
        {LeftBtn && RightBtn ? (
          <div className={s.navigContainer}>
            <LeftBtn
              style={props.whiteButtons ? { color: '#FFF', backgroundColor: 'unset', ...additionalStyleForLeftRightButtons } : {}}
              leftClickHandler={(e) => {
                e.stopPropagation();
                setSlideToShow(slideToShow === 0 ? images.length - 1 : slideToShow - 1);
              }}
            />

            <RightBtn
              style={props.whiteButtons ? { color: '#FFF', backgroundColor: 'unset', ...additionalStyleForLeftRightButtons } : {}}
              rightClickHandler={(e) => {
                e.stopPropagation();
                setSlideToShow(slideToShow === images.length - 1 ? 0 : slideToShow + 1);
              }}
            />
          </div>
        ) : null}
        {ActiveIcon && InActiveIcon ? (
          <div
            className={s.dotsContainer}
            style={{ ...props.styleForSliderIndicator, ...(images.length === 1 ? ({ height: '0.625rem' }) : {}) }}
          >
            { images.length > 1 && images.map((image, index) => (index === slideToShow ? (
              <ActiveIcon handleNavClick={props.dotsTapable ? () => setSlideToShow(index) : () => {}} />
            ) : (
              <InActiveIcon handleNavClick={props.dotsTapable ? () => setSlideToShow(index) : () => {}} />
            )))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Carousel;
