import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const getServiceById = (catId, id, carId, cityId) => {
    const Headers = new HttpHeader();
    const ApiManager = new Global();
    const HttpHandler = new Http();
  
    const headers = Headers.get_token_headers();
    const url = `${ApiManager.urls.api_get}v4/oauth/customer/get_service_by_id?category_id=${catId}&id=${id}&car_id=${carId}&city_id=${cityId}`;
    return HttpHandler.get(url, headers);
  };