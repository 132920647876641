import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import css from '../components/variables.css';
import { setDeviceType } from '../actions/device';
import { deviceTypes } from '../_services/device';

const HideOnMobileTab = (props) => {
  useStyles(css);

  if (props.isCrawlerRequest) return <div className={css.hideOnMobileTab}>{props.children}</div>;

  return (
    <>
      {props.device !== deviceTypes.TABLET
      && props.device !== deviceTypes.MOBILE ? (
        <div className={css.hideOnMobileTab} style={props.style}>{props.children}</div>
        ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setDeviceAction: (value) => dispatch(setDeviceType(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HideOnMobileTab);
