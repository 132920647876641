import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import s from './css/main.css';
import HideOnMobile from '../../_wrappers/HideOnMobile';
import HideOnTabPC from '../../_wrappers/HideOnTabPC';
import BottomSlider from '../InsuranceServicesHelpers/BottomSlider/BottomSlider';
import BlogSection from '../InsuranceServicesHelpers/BlogSection/BlogSection';
import NewBlogSection from '../InsuranceServicesHelpers/NewBlogSection/NewBlogSection';
import { getPopularService } from '../../_services/api/popularService';
import { getPopularServiceV2 } from '../../_services/api/getPopularServiceV2';
import { setPopularServiceV2 } from '../../actions/setPopularServiceV2';

const PopularService = (props) => {
  useStyles(s);

  const [service, setServices] =useState({
    formAPI: false,
    data: props.home?.popularServiceV2,
  });

  const carExists = props.cardetails?.brand && props.cardetails?.model && props.cardetails?.fuel;

  useEffect(() => {
    if(!carExists) {
      // console.log("car is not set");
      !Object.keys(props.home?.popularServiceV2 ?? {}).length && getPopularServiceV2()
      .subscribe(res => {
        if(res.data){
          props.action(setPopularServiceV2(res.data));
          setServices({
            formAPI: false,
            data: res.data,
          });
        }
      }, err => {
        console.log(err);
      })
      return;
    }
    
    if(carExists) {
      getPopularService(
        props.cardetails?.fuel?.car_type_id,
        props.city?.id).subscribe((res) => {
          if(res.data) {
            setServices({
              formAPI: true,
              data: res.data.data,
            });
          }
        }, (err) => {
          console.error(err);
        })
    }
    else {
      console.log("car is not setting")
    }
  }, [props.cardetails, props.city, props.user])

  if(!Object.keys(props.home?.popularServiceV2 ?? {}).length)
  return null;

  return (
    <>
     
     <HideOnMobile>
            <div style={{ marginTop: '1rem' }}>
                <BottomSlider
                  popularServices = {1}
                  scrollButtons
                  content={service?.data?.map((item) => (
                    <NewBlogSection
                      articleImage={item.trending_image_v2}
                      popularServices={1}
                      seviceText={item.trending_name}
                      description={item.trending_description}
                      pastBooking={item.past_bookings}
                      serviceSlug ={item.trending_service_slug}
                      serviceData ={item}
                      trendingText={item.trending_text}
                      deeplink={item.deeplink}
                      carId = {props.cardetails && props.cardetails?.fuel
                        ? props.cardetails?.fuel?.car_type_id : null}
                    />    
                  ))}
                />
              </div>
            </HideOnMobile>

            <HideOnTabPC>
             <div style={{ marginTop: '1rem' }}>
                <BottomSlider
                  popularServices = {1}
                  content={service?.data?.map((item) => (
                    <NewBlogSection
                      typeId={item.id ?? idx}
                      articleImage={item.trending_image_v2}
                      popularServices={1}
                      seviceText={item.trending_name}
                      description={item.trending_description}
                      pastBooking={item.past_bookings}
                      serviceSlug ={item.trending_service_slug}
                      serviceData ={item}
                      trendingText={item.trending_text}
                      deeplink={item.deeplink}
                      carId = {props.cardetails && props.cardetails?.fuel
                        ? props.cardetails?.fuel?.car_type_id : null}
                    />
                  ))}
                />
              </div>
            </HideOnTabPC>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopularService);
