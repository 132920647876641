import React, { useState } from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/HomeVideo.css';
import HideOnMobile from '../../../_wrappers/HideOnMobile';
import HideOnTabPC from '../../../_wrappers/HideOnTabPC';
import play from '../playIcon.png';
import HomeVideoPop from './HomeVideoPop';


const HomeVideo = (props) => {
  const [showVideoPop, setShowVideoPop] = useState(false);
  const [player, setPlayer] = useState(true);
  const hideScroll = () => {
    var x = document.getElementsByTagName("body")
    x[0].style.overflow = "hidden";
  }
  useStyles(s);


  return (
    <>
    {showVideoPop ? (
        <HomeVideoPop hide={() => {
          setShowVideoPop(false);
          setPlayer(true);
          var z = document.getElementsByTagName("body")
          z[0].style.overflow = "scroll";
        }}
        />
      ) : null }
      <HideOnMobile>
        <div className={s.flexBox}>
          <div className={s.videoBox} onClick={(e) => {  e.preventDefault(); setShowVideoPop(true);  setPlayer(false); hideScroll();}}>
            <img src={props.home?.customerVideo?.thumbnail} className={s.bgImg} />
            <div className= {player ? s.visibles : s.hides}>
              <img src ={play} />
            </div>
          </div>
          <div className={s.container}>
            {props.home?.customerVideo && props.home?.customerVideo?.rating.map((ratingItem) => (
              <div className={s.ratingItemVideo}>
                <span>
                  {ratingItem.title}
                  {ratingItem.subtitle === 'Average Rating'
                    ? (<img style={{ width: '1.25rem', height: '1.25rem', marginLeft: '0.5rem', marginTop: '-5px', }} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/star.png" alt="star" />
                    ) : null }
                </span>
                <span className={s.subHeading}>{ratingItem.subtitle}</span>
              </div>
            ))}
          </div>
        </div>
      </HideOnMobile>
                
      <HideOnTabPC>
        <div className={s.videoCard} onClick={(e) => {  e.preventDefault(); setShowVideoPop(true);  setPlayer(false); hideScroll();}}>
          <div className={s.playIc}>
            <img 
              className= {player ? s.visible : s.hides} 
              src ={play} />
          </div>
          <img src={props.home?.customerVideo?.mobileThumbnail} className={s.bgImg} />
        </div>
      </HideOnTabPC>  
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeVideo);
