/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
// import useStyles from 'isomorphic-style-loader/useStyles';
import Glide from '@glidejs/glide';
import { connect } from 'react-redux';
import Carousel from '../Carousel';
import { LeftBtn, RightBtn } from '../Carousel/PrevNextButtons';

const CarouselNew2 = (props) => {
  useEffect(() => {
    new Glide('.glide', {
      type: 'carousel',
    }).mount();
  }, []);
  let images=props.home.cityWiseCarousel[props.city?.sitemap_name] || props.home.images;

  // if (images.length > 8) {
  //   images = images.slice(0, 8);
  // }
  if (props.mobileHomepage) {
    return (
      <Carousel
        whiteButtons
        slideCustomStyle={{
          width: '100%',
          height: 'calc(100vw / 2.06)',
        }}
        slideShowCustomStyle={{
          margin: '0rem',
        }}
      // inActiveIcon={InActiveIcon}
      // autoSlideTimer={1500}
      // activeIcon={ActiveIcon}
      // styleForSliderIndicator={{
      //   marginTop: '-1.5625rem',
      //   marginRight: 'auto',
      //   marginLeft: '3.125rem',
      // }}
        leftBtn={LeftBtn}
        rightBtn={RightBtn}

        images={images}
      />
    );
  }
  return (
    <>
      <div className="glide">
        <div
          className="glide__track"
          style={{
            // marginTop: '-10px',

          }}
          data-glide-el="track"
        >
          <ul className="glide__slides">


            {(images.map((img) => (
              <li className="glide__slide imageCarouselHomepage" style={props.slideStyle || {}}>
                <img
                  style={props.imgStyle || {}}
                  loading="lazy"
                  src={img}
                  alt={props.alt || 'banner'}
                />
              </li>
            )))}
          </ul>
        </div>
        {props.arrow ? (
          <div
            className="glide__arrows"
            data-glide-el="controls"
            style={{
              position: 'absolute',
              top: '40%',
              width: '100vw',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <button
              className="glide__arrow glide__arrow--left"
              data-glide-dir="<"
              type="button"
            >
              <img
                src="/graphics/Icons/rightArrow.svg"
                style={{
                  transform: 'rotate(180deg)',
                }}
                alt="Previous"
              />
            </button>
            <button
              className="glide__arrow glide__arrow--right"
              data-glide-dir=">"
              type="button"
            >
              <img
                src="/graphics/Icons/rightArrow.svg"
                alt="Next"
              />
            </button>
          </div>
        ) : null}
        {props.bullets ? (
          <div className="glide__bullets" data-glide-el="controls[nav]">
            {images.map(
              (image, index) => <button className="glide__bullet" data-glide-dir={`=${index}`} type="button" />,
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarouselNew2);
