import classNames from 'classnames';
import React from 'react';
import s from './Carousel.css';

export const ActiveIcon = (props) => (
  <img loading='lazy'
    className={classNames(s.dot)}
    onClick={props.handleNavClick}
    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Carousel/6A41C899-BC88-4DB5-B552-3BCA3B7F3618.svg" alt="Active Inactive Icons"
  />
);

export const InActiveIcon = (props) => (
  <img loading='lazy'
    className={classNames(s.dot)}
    onClick={props.handleNavClick}
    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Carousel/9B290245-4E29-4CB1-B58C-B671DA72A880.svg" alt="Active Inactive Icons"
  />
);
