import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import s from './css/main.css';

const Rating = (props) => {
  useStyles(s);
  return (
    <div  style={{ ...props.customContainer }} className={ props.nearMe ? s.container2 : s.container}>
      {props.ratingItems && props.ratingItems.length > 0 ? (
        props.ratingItems.map((ratingItem) => (
          <div className={s.ratingItem} style={{ ...props.customItem }} key={Math.random()}>
            <span>
              {ratingItem.title}
              {ratingItem.subtitle === 'Average Rating'
                ? (<img style={{ width: '1.25rem', height: '1.25rem', marginLeft: '0.5rem', marginTop: '-5px', }} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/star.png" alt="star" />
                ) : null }
            </span>
            <span className={s.subHeading}>{ratingItem.subtitle}</span>
          </div>
        ))
      ) : (
        <>
          <div className={classNames(props.nearMe ? s.ratingItem21 : s.ratingItem,s.rightBorder)}>
              <div className={classNames(props.nearMe ? s.ratingItem2 : s.ratingItem,s.pad)}>
                <div>
                  <div className={s.displayFlex}>
                    <img loading='lazy'
                      className={s.startIcon}
                      src="https://gomechanic.in/assets/img/customerpage/formIcons/star_rating.png" alt="Rating"
                    />
                    <div className={s.prt}>
                      <span style={{
                        color: '#008000',
                      }} className={props.nearMe ? s.score : null}>4.0/</span>
                      {/* {' '} */}
                      <span style={{
                        color: '#008000',
                      }} className={props.nearMe ? s.totalRating1 : s.totalRating}>5</span>
                    </div>
                  </div>
                </div>
                <div className={s.subHeading}>{props.isMalaysiaRequest ? `Based on 1000+` : `Based on 150000+`}<div>Reviews</div></div>
              </div>
          </div>
          <div className={classNames(props.nearMe ? s.ratingItem21 : s.ratingItem)}>
              <div className={classNames(props.nearMe ? s.ratingItem2 : null,s.prt)}>
                <div className={classNames(props.nearMe ? s.score : s.scoreSize,s.textCenter)}>{props.isMalaysiaRequest ? '3000+' : '2 Million+'}</div>
                <div className={classNames(s.subHeading,s.pading)}>Happy <div>Customers</div></div>
              </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
