/**
 * Author: Rishabh Tyagi <rishabhtyagi@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a54d2d3af23439013ad
 *
 * This file contains code for Gomechanic Benefits Section for PC
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { connect } from 'react-redux';
import s from './css/main.css';
import classNames from 'classnames';
import HideOnMobile from '../../../_wrappers/HideOnMobile';

const HomeVideoPop = (props) => {

  useStyles(s);
  return (
    <>
      <div onClick={props.hide} className={s.overlay} />
      <div className={classNames(s.modaldialog, s.modalheight)}>
        <div className={s.popBox}>
          <div className={s.closeIns}>
            <img src="/icons/ic_close_24px.png" className={s.closeimg1} onClick={props.hide} />
          </div>
               
          <div className={s.videoFrame}>
            <video source controlslist="nodownload" tabindex="0" src={props.home?.customerVideo?.video} type="video/mp4" controls autoplay="true" />
          </div>    
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeVideoPop);
